<template>
  <div class="card">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-4">
          <h5 class="text-danger px-2 py-3">COMPANY INSTRUCTION</h5>
        </div>
      </div>
    </div>
    <div class="row mx-2 align-items-center mb-2">
      <div class="col-lg-3">
        <h6 class="font-weight-bold mb-0">REPORT NUMBER : </h6>
      </div>
      <div class="col-lg-8">
        <template>
          <input type="text" class="form-control form-control-sm"
                 disabled
                 v-model="instruction.taggable_code"
                 name="report-number"
                 id="report-number">
        </template>
      </div>
    </div>
    <div class="row mx-2 align-items-center mb-2">
      <div class="col-lg-3">
        <h6 class="font-weight-bold mb-0">INSTRUCTION : </h6>
      </div>
      <div class="col-lg-8">
        <template>
          <input type="text" class="form-control form-control-sm"
                 disabled
                 v-model="instruction.title"
                 name="instruction"
                 id="instruction">
        </template>
      </div>
    </div>
    <div class="row mx-2 align-items-center mb-2">
      <div class="col-lg-3">
        <h6 class="font-weight-bold mb-0">TYPE OF INSTRUCTION : </h6>
      </div>
      <div class="col-lg-8">
        <template>
          <input type="text" class="form-control form-control-sm"
                 disabled
                 v-model="instruction.instruction_type"
                 name="report-number"
                 id="report-number">
        </template>
      </div>
    </div>
    <div class="row mx-2 align-items-center mb-2">
      <div class="col-lg-3">
        <h6 class="font-weight-bold mb-0">RELATED PARTIES : </h6>
      </div>
      <div class="col-lg-8">
        <template>
          <input type="text" class="form-control form-control-sm"
                 disabled
                 v-model="instruction.related_party"
                 name="report-number"
                 id="report-number">
        </template>
      </div>
    </div>
    <div class="row mx-2 align-items-center mb-2">
      <div class="col-lg-3">
        <h6 class="font-weight-bold mb-0">INSTRUCTION DETAIL : </h6>
      </div>
      <div class="col-lg-8">
        <template>
          <div id="textArea" v-html="instruction.detail" class="pre-wrap-txt textarea-display-card"/>
        </template>
      </div>
    </div>
    <div class="row mx-2 align-items-center mb-2">
      <div class="col-lg-3">
        <h6 class="font-weight-bold mb-0">INSTRUCTION ATTACHMENTS : </h6>
      </div>
      <div class="col-lg-7">
        <div class="row">
          <div class="col-2"  v-for="attachment in instructionFiles">
            <other-comp :no-delete="true"
                        @viewOther="viewInstructionAttachment(attachment,false)"
                        :attachment="attachment" attachment-name="name" />
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-2 align-items-center mb-2">
      <div class="col-lg-3">
        <h6 class="font-weight-bold mb-0">VESSELS : </h6>
      </div>
      <div class="col-lg-8">
        <div class="row" style="max-height: 40vh;overflow: auto">
          <div class="col-6 mb-1" v-for="vessel in instruction.vessels ">
            <div class="row">
              <div class="col-4 align-self-center">
                <div class="row align-items-center">
                  <font-awesome-icon icon="ship" /> {{vessel.vessel | toUpper}}
                </div>
              </div>
              <div class="col-3 align-self-center">
                          <span class="font-weight-bolder" :class="{
                          'text-danger':vessel.cancelled_at,
                          'text-574e4e':!vessel.cancelled_at && vessel.job_status_id ==1,
                          'text-056cb4':!vessel.cancelled_at && vessel.job_status_id ==2,
                          'text-42b983':!vessel.cancelled_at && vessel.job_status_id ==3,
                          }">{{ vessel.cancelled_at ? 'CANCELLED' : vessel.job_status | toUpper }}
                          </span>
              </div>
              <div class="col-2 align-self-center">
                          <span class="font-weight-bolder" :class="{
                              'text-danger':vessel.cancelled_at,
                              'text-4c4c4c':!vessel.cancelled_at && vessel.acknowledgement_status_id ==1,
                              'text-042454':!vessel.cancelled_at && vessel.acknowledgement_status_id == 2,
                              'text-42b983':!vessel.cancelled_at && vessel.acknowledgement_status_id == 3,
                              'text-1b4f72':!vessel.cancelled_at && vessel.acknowledgement_status_id == 4,
                            }">
                          {{vessel.cancelled_at ? 'CANCELLED' : vessel.acknowledgement_status | toUpper }}
                        </span>
              </div>
              <div class="col-1 align-self-center">
                <button type="button" title="View Instruction in New Tab" class="e-btn e-btn-blue btn-xs" @click="viewVesselResponse(vessel)">
                  <font-awesome-icon icon="share" class="force-white-all" /> <small class="text-white">View</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherComp from "@/components/elements/OtherComp.vue";
import {mapActions, mapGetters} from "vuex";
import {CI_INDEX, CI_VESSEL_INDEX} from "@/services/ConstantService";
import axios from "axios";
import {FileService} from "@/services/FileService";
import {AlertService} from "@/services/AlertService";

export default {
  name: "StaffGeneralCompanyInstruction",
  components: {OtherComp},
  data(){
    return {
      instruction:{},
    }
  },
  computed:{
    ...mapGetters(['instructionFiles'])
  },
  methods:{
    ...mapActions(['getCompanyInstruction','getCompanyInstructionAttachments']),
    async fetchCompanyInstruction(){
      const response = await this.getCompanyInstruction({id:this.$route.query.id})
      if(response){
        this.instruction=Object.assign({},response)
      }
    },
    async viewVesselResponse(vessel){
      var w = window.window.innerWidth + 100;
      var h= window.window.innerHeight + 100;
      const route = this.$router.resolve(
        {
          name: 'StaffVesselResponse',
          query:{
            id:vessel.id
          }
        },
      )
      window.open(route.href, '_blank',`toolbar=yes,scrollbars=yes,resizable=yes,width=${w},height=${h}`)
    },
    async viewInstructionAttachment(attachment, fromVessel = true) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();

      const mainRoute = fromVessel ? CI_VESSEL_INDEX : CI_INDEX

      const url = [mainRoute, attachment.referencable_id, 'attachment', attachment.id].join('/')

      const response = await axios.get(url, {responseType: 'blob'})

      if (response && response.status == 200) {
        if (attachment.extension === 'pdf' || attachment.mime_type.includes("image")) {
          await FileService.viewDocument(response.data, attachment)
        } else {
          await FileService.downloadDocument(response.data, attachment, 'name');
        }
        swal.close();
      } else {
        AlertService.errorAlert('File not exists', "View Attachment")
      }
    },
  },
 async  created(){
    await this.fetchCompanyInstruction();
    await this.getCompanyInstructionAttachments({id:this.$route.query.id})
  }
}
</script>

<style scoped>

</style>
